import axios from 'axios'

export default {
  mounted() {
    this.$nextTick(function () {
      this.updateForm()
    })
  },
  watch: {
    biddingTemplate: function () {
      this.updateForm()
    },
  },
  data() {
    return {
      repairs: [],
      contributionRegex: /@\s*(\d+)\s*%/,
    }
  },

  methods: {
    processStep: function () {
      this.loading = true
      if (this.nextSection === 'to-vehicle-photos') {
        this.repairs = []
        this.parts.forEach((repair) => {
          this.repairs.push({
            part: repair.part.concat(
              repair.contribution > 0
                ? ` @ ${repair.contribution}% CONTRIBUTION`
                : ''
            ),
            contribution: repair.contribution,
            type: repair.type,
            unitPrice: repair.unitPrice,
            quantity: repair.quantity,
            vat: repair.vat,
          })
        })

        this.$store.commit('bidForm/updateBiddingForm', {
          repairs: this.repairs,
          additionalInformation: this.additionalInformation,
        })
      } else {
        this.$store.commit('bidForm/updateBiddingForm', this.form)
      }
      axios
        .post(
          `/motor-assessment/api/bidding-template/${this.booking.id}/BIDDING_TEMPLATE_IN_PROGRESS`,
          this.$store.getters['bidForm/biddingForm']
        )
        .then((response) => {
          if (response.data.success) {
            this.loading = false
            this.showSnackbarMessage('success', 'Progress saved', 'success')
            this.$emit(this.nextSection)
          } else {
            this.showSnackbarMessage('failed', response.data.msg, 'error')
            this.loading = false
          }
        })
        .catch(() => {
          this.showSnackbarMessage(
            'failed',
            'An error occurred, try again',
            'error'
          )
          this.loading = false
        })
    },

    removeContributionText: function () {
      this.repairs.forEach((repair) => {
        this.repairs.push({
          part: repair.part
            .replace(this.contributionRegex, '')
            .replace('CONTRIBUTION', '')
            .trim(),
          contribution: repair.contribution,
          type: repair.type,
          unitPrice: repair.unitPrice,
          quantity: repair.quantity,
          vat: repair.vat,
        })
      })
      this.$store.commit('bidForm/updateBiddingForm', {
        repairs: this.repairs,
      })
    },

    showSnackbarMessage: function (state, message, variant) {
      this.$store.commit('SNACKBAR', {
        state: state,
        message: message,
        variant: variant,
      })
    },

    getTotal(item) {
      let am = item.unitPrice * item.quantity
      if (item.vat) am += this.getVat(am)
      return (am -= (item.contribution / 100.0) * am)
    },

    getVat(amount) {
      return (this.vatRate / 100) * amount
    },

    extractContribution: function (item) {
      const match = item.part.match(this.contributionRegex)
      if (match != null && item.part.endsWith('CONTRIBUTION')) {
        return Number(match[1])
      }
      return 0
    },

    updateParts: function () {
      this.parts.forEach((item) => {
        item.contribution = this.extractContribution(item)
        item.part = item.part.replace(this.contributionRegex, '').replace('CONTRIBUTION', '')
      })
    },
  },
  computed: {
    netTotal() {
      return this.parts.reduce((result, item) => {
        return result + item.unitPrice * item.quantity
      }, 0)
    },

    vatTotal() {
      return this.parts
        .filter((i) => i.vat === true)
        .reduce((res, item) => {
          return res + this.getVat(item.unitPrice * item.quantity)
        }, 0)
    },

    contributionTotal() {
      return this.parts.reduce((res, item) => {
        let am = item.unitPrice * item.quantity
        if (item.vat) am += this.getVat(am)
        if (item.contribution == null)
          item.contribution = this.extractContribution(item)
        return res + (item.contribution / 100.0) * am
      }, 0)
    },

    grandTotal() {
      return this.vatTotal + this.netTotal - this.contributionTotal
    },
  },
}
